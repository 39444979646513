import React from 'react'
import { SuperTag } from '~components'
import { Link } from '~components'
import Card from './style'

export default function ServicesCard({ image, title, text, to, linkTitle, ...rest }) {
  return (
    <Card itemCenter={true} {...rest}>
      <Card.Image src={image} alt="logo" />
      <Card.Title as="h3">{title}</Card.Title>
      <Card.Text><SuperTag value={text} /> </Card.Text>
      <Link to={to}>
        {linkTitle}
      </Link>
    </Card>
  )
}


