import React from "react";
import { PageWrapper } from "~components/Core";
import HeroSection from '~sections/Common/Hero'
import PartnersSection from '~sections/Industries/Partners'
import ContentSectionOne from '~sections/Partners/ContentOne'
import TestimonialSection from '~sections/Industries/Testimonial'
//import PromoSection from '~sections/Partners/Promo'
//import CtaSection from '~sections/Partners/Cta'
import Footer from '~sections/Common/Footer'
import partners from "~data-yaml/partners.yml"
import HeroBg from "~image/heroimages/partners/hero_bg.jpg"
import heroOverlay from "~image/heroimages/partners/overlay.png"

const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid:false,
  darkLogo:false,
}

export default function HomeApp() {
  return (
    <PageWrapper headerConfig={header}>
        <HeroSection 
          title={partners.hero.title}
          subtitle={partners.hero.subtitle}
          text={partners.hero.text}
          HeroBg={HeroBg}
          Overlay={heroOverlay}
        />
        <PartnersSection/>
        <ContentSectionOne/>
        <TestimonialSection/>
        <Footer/>
    </PageWrapper>
  )
}
