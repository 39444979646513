import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { Link } from '~components'
import ServicesCard from './Components/Card'
import Service from './style'
//import SectionTitle from './Components/SectionTitle'
import SectionTitle from '~sections/Common/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
import Data from "~data-yaml/partners.yml"

const PartnersSection = () => {
  const data = useStaticQuery(graphql`
  query {
    allMdx(filter: {frontmatter: {type: {eq: "partnerDetails"}}},sort: {fields: [frontmatter___title], order: ASC}) {
      nodes {
        id
        excerpt
        slug
        frontmatter {
          type
          title
          logo
          linkTitle
          image
        }
      }
    }
  }
`)
  return (
    <Service>
      <Container className="container">
        <Row className="row justify-content-center">
          <Col className="col-xl-8 text-center">
            <SectionTitle
              subTitle={Data.p_section.subtitle}
              title={Data.p_section.title}
              text={Data.p_section.text}
              titleProps={{ mb: "40px", mbLG: "75px" }}
              subTitleProps={{ mb: "20px" }}
            />
          </Col>
        </Row>
        <Row className="row justify-content-center">
          {data.allMdx.nodes.map((p, i) => {
            return (
              <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8" key={i}>
                <ServicesCard
                  key={"partner-" + i}
                  title={p.frontmatter.title}
                  text={p.excerpt}
                  image={p.frontmatter.logo}
                  to={"/"+p.slug}
                  linkTitle={p.frontmatter.linkTitle}
                   />
              </Col>
            )
          })}

        </Row>
      </Container>
    </Service>
  )
}

export default PartnersSection

